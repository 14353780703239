import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "../Images/Send.svg";
import logo192 from "../Images/logo192.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../Config/BackEnd-Config";
import Loader from "./Loader";
import "./Sidebar.css";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles((theme) => ({
  closeicon: {
    textAlign: "right",
    height: "100%",
  },
  box: {
    width: "100%",
    margin: "0px auto",
    textAlign: "center",
    height: "100%",
  },
  img: {
    width: "100px",
    padding: "30px 0",
  },
  button: {
    marginBottom: "10px",
    background: "#4a90e2",
    width: "219px",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#4a90e2",
    },
  },
  typography: {
    color: "#091e42",
    fontSize: 14,
    fontWeight: 400,
  },
  chatArea: {
    display: "inline-block",
    position: "relative",
    width: "100%",
  },
  formControl: {
    border: "2px solid #dfdbdb",
    borderRadius: "50px",
    boxShadow: "1px 0 9px 0 rgba(0,0,0,.15)",
    color: "#000",
    fontFamily: "Poppins,sans-serif",
    fontSize: "15px",
    height: "auto",
    lineHeight: "39px",
    padding: " 5px 0px 5px 20px",
    transition: ".5s",
    display: "block",
    width: "-webkit-fill-available",
    width: "-moz-available",
  },
  btnChat: {
    alignItems: "center",
    backgroundColor: "#098aff",
    backgroundColor: "var(--themeColor)",
    border: 0,
    borderColor: "#098aff",
    borderColor: "var(--themeColor)",
    borderRadius: "25px",
    color: "#fff",
    display: "flex",
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    padding: "8px 16px",
    position: "absolute",
    right: "6px",
    top: "7px",
    cursor: "pointer",
  },
  // chatFooter: {
  //   padding: "0 15px 15px 20px",
  //   position: "relative",
  // },
  chatBody: {
    height: "calc(100% - 180px)",
    overflow: "auto",
    padding: "20px 20px 10px",
    marginBottom: "10px",
  },
  chatMain: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  chats: {
    float: "left",
    marginBottom: "15px",
    textAlign: "left",
    width: "100%",
    display: "flex",
  },
  userChats: {
    float: "right",
    marginBottom: "15px",
    textAlign: "right",
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  chatLabel: {
    color: "#000",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
    padding: "8px",
    background: "#fff",
    border: "1px solid #d6d6d6",
    borderRadius: "0px 10px 10px 10px",
    boxShadow: "0 1px 5px 0 rgba(0,0,0,.15)",
    display: "inline-block",
    margin: "0 15px",
    maxWidth: "calc(100% - 110px)",
  },
  userChatLabel: {
    backgroundColor: "#098aff",
    borderRadius: "10px 0 10px 10px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
    padding: "8px",
    border: "1px solid #d6d6d6",
    boxShadow: "0 1px 5px 0 rgba(0,0,0,.15)",
    display: "inline-block",
    margin: "0 15px",
    maxWidth: "calc(100% - 110px)",
  },
  avatarImg: {
    float: "left",
    background: "#fff",
    border: "1px solid rgba(0,0,0,.37)",
    borderRadius: "60px",
    boxShadow: "0 1px 4px 0 rgba(0,0,0,.15)",
    display: "inline-block",
    height: "20px",
    width: "20px",
  },
  imgDiv: {
    display: "flex",
    alignItems: "center",
  },
  chatHeader: {
    alignItems: "center",
    backgroundColor: "#162b6b",
    boxShadow: "0 3px 7px rgba(0,0,0,.15)",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 25px",
  },
  avatarImg: {
    marginRight: "30px",
  },
}));

const Sidebar = ({
  setIsOpenSidebar,
  isOpenSidebar,
  chartsArr,
  setChartsArr,
}) => {
  const classes = useStyles();
  const [chat, setChat] = useState("");
  const inputReference = useRef();

  useEffect(() => {
    if (isOpenSidebar) {
      inputReference?.current?.focus();
    }
  }, [isOpenSidebar]);

  let [loading, setLoading] = useState(false);
  const listRef = useRef();

  useEffect(() => {
    listRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [chartsArr]);

  const handleChat = async (e) => {
    e.preventDefault();

    if (!chat || chat == "") {
      alert("Please enter message");
      return;
    } else {
      setLoading(true);
      setChartsArr((prev) => [
        ...prev,
        {
          id: uuid(),
          sender: "user",
          message: chat,
        },
      ]);
      // passed request to backend...

      try {
        const res = await axios.post(`${BACKEND_URL}/user`, {
          data: chat,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        });
        setChartsArr((prev) => [
          ...prev,
          { id: uuid(), sender: "bot", message: res.data.data },
        ]);
        setLoading(false);
        setChat("");
      } catch (err) {
        setLoading(false);
        setChat("");
        setChartsArr((prev) => [
          ...prev,
          { id: uuid(), sender: "bot", message: err.response.data.data },
        ]);
      }
    }
  };
  return (
    <div className={classes.closeicon}>
      <Box color="text.primary" className={classes.box}>
        <div className={classes.chatHeader}>
          <div className={classes.imgDiv}>
            <img src={logo192} alt="img" style={{ width: "30px" }} />
          </div>

          <div>
            <IconButton onClick={() => setIsOpenSidebar(false)}>
              <CloseIcon style={{ color: "White" }} />
            </IconButton>
          </div>
        </div>

        <div className="chatBody">
          <ul className={classes.chatMain}>
            {chartsArr.length > 0 &&
              chartsArr.map((ele, index) => {
                return (
                  <div key={ele.id}>
                    {ele.sender === "user" ? (
                      <li className={classes.userChats}>
                        <div className={classes.userChatLabel}>
                          {ele.message}
                        </div>
                        <div className={classes.avatarImg}>
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAsCAYAAAD8WEF4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIcSURBVHgB7ZfNSsNAFIVPtf6hooILFRdFXLgQceH7P4ML3aiLLiqtIqWLirW21ntIBmOan8m907rJB4dAE9Ivk5u5M0BNTU1NzSJpIBz7kqP4uBn/NozTkwxgJIQsxS4QSRZB4bZkBCWrsEHRa8mOx7W85lDyJplAgVX2Br+v3IcmImGO8jcqYpFtxX9cFQpTtHINr0DPEfScQoFWNvnFa2ii/IOcQyvr80EFv4dWtgk7le+hlVVNPdZ7aGWHsFP5HhZZy+iyiy1t6qJoB3p6UGCZZymr6fMjKB/U0sHYhdjn2cV8v2yK3krGUGBdG7AcKMw5s6xJsEbvYFh1hVzPcoTdetaNNB+Gkh0EWM/W1CBMzTbhPxtMYGgmWtmszaEv6k1kVVnKcXNoWcsm4TTWhmdH85Xlaz6HbXdQBGWfUFIiPrIcxUuEWXAX4bpbbtMo62Ac0SssXtT9F8vsFTk73zLZM+h2sFrWES2u+lkni1ZdHE3VLtQI/zNzM1kk28L/8Cn5yjpRVAYfkj1Er2ZZvEvuEQnP1W2eLGeJqeRFsibZxeLpSh4Ric6yLiiSdQziG2wjzBY8DefWB8kzogGaIUc2b55tJI4uW5ITyTHCSXbjjBOSlWWT59zRfYyUZi3zq9W0XdZlPyVJkjVaWTZ9TSMVQlmKHyAqkQ38fQB2o2ks6CRHCZn0SGZKpkV8aOTIZ51LMss4znLOFfIDkutxHv77nQUAAAAASUVORK5CYII="
                            alt="img"
                            style={{ width: "30px" }}
                          />
                        </div>
                      </li>
                    ) : (
                      <li className={classes.chats} ref={listRef}>
                        <div className={classes.imgDiv}>
                          <img
                            src={logo192}
                            alt="img"
                            style={{ width: "30px" }}
                          />
                        </div>

                        <div
                          className={classes.chatLabel}
                          key={index}
                          id={index}
                        >
                          {ele.message}
                        </div>
                      </li>
                    )}
                  </div>
                );
              })}
          </ul>
          {loading && (
            <div className={classes.chats}>
              <div className={classes.imgDiv}>
                <img src={logo192} alt="img" style={{ width: "30px" }} />
              </div>
              <div className={classes.chats}>
                <Loader />
              </div>
            </div>
          )}
        </div>

        <div className="chatFooter">
          <form onSubmit={handleChat}>
            <div className={classes.chatArea}>
              <input
                type="text"
                id="myInput"
                className={`${classes.formControl} inputField`}
                placeholder="Send a Message"
                value={chat}
                ref={inputReference}
                onChange={(e) => {
                  setChat(e.target.value);
                }}
                autoComplete="off"
                autoFocus
              />

              <button
                type="submit"
                className={`${classes.btnChat} btnPrimary`}
                id="sendBtn"
                onClick={handleChat}
              >
                Send
                <img src={SendIcon} />
              </button>
            </div>
          </form>
        </div>
      </Box>
    </div>
  );
};
export default Sidebar;
